import { listMenus, getMenu } from '@wix/ambassador-restaurants-menus-v1-menu/http';
import type { Menu } from 'root/apiTypes';
import type { HttpClientFactory } from './utils/types';
import { sortByCreatedDate } from '@wix/restaurants-normalizers';

export const MenusClient: HttpClientFactory<Menu> = (httpClient, withErrorHandler) => ({
  get: ({ id }) => {
    const callback = async () => {
      const res = await httpClient.request(getMenu({ menuId: id }));
      const {
        data: { menu = {} },
      } = res;

      return { data: menu as Menu };
    };
    return withErrorHandler ? withErrorHandler(callback, { errorCodesMap: {} }) : callback();
  },
  getAll: ({ paging, onlyVisible = true }) => {
    const callback = async () => {
      const res = await httpClient.request(listMenus({ paging, onlyVisible }));
      const sortedMenus = sortByCreatedDate((res?.data?.menus || []) as (Menu & { createdDate: Date })[]);

      return { data: sortedMenus as Menu[], pagingMetadata: res?.data?.pagingMetadata };
    };

    return withErrorHandler ? withErrorHandler(callback, { errorCodesMap: {} }) : callback();
  },
});
