import { getSection, listSections } from '@wix/ambassador-restaurants-menus-v1-section/http';
import type { Section } from 'root/apiTypes';
import type { HttpClientFactory, SectionCursorPagingMetadata } from './utils/types';
import { getIdsChunks } from './utils/entityUtils';
import { MAX_NUMBER_OF_ENTITIES_FROM_SERVER_GET_REQUEST } from 'root/consts';

export const SectionsClient: HttpClientFactory<Section> = (httpClient, withErrorHandler) => ({
  get: ({ id }) => {
    const callback = async () => {
      const res = await httpClient.request(getSection({ sectionId: id }));
      const {
        data: { section = {} },
      } = res;
      return { data: section as Section };
    };

    return withErrorHandler ? withErrorHandler(callback, { errorCodesMap: {} }) : callback();
  },
  getAll: ({ ids, paging, onlyVisible = true }) => {
    const callback = async () => {
      let sections: Section[] = [];
      let pagingMetadata: SectionCursorPagingMetadata | undefined;

      if (ids?.length) {
        const idChunks = getIdsChunks(ids, MAX_NUMBER_OF_ENTITIES_FROM_SERVER_GET_REQUEST);
        const sectionsInChunks = await Promise.all(
          idChunks.map(async (chunk) => {
            const { data } = await httpClient.request(
              listSections({
                onlyVisible,
                sectionIds: chunk || [],
              })
            );
            return data.sections || [];
          })
        );

        sections.push(...sectionsInChunks.flatMap((section) => section as Section[]));
      } else {
        const { data } = await httpClient.request(
          listSections({
            paging,
            onlyVisible,
          })
        );
        sections = data.sections as Section[];
        pagingMetadata = data.pagingMetadata;
      }

      return {
        data: sections || [],
        pagingMetadata,
      };
    };

    return withErrorHandler ? withErrorHandler(callback, { errorCodesMap: {} }) : callback();
  },
});
