import type { PopulatedMenu, ShowcasePopulatedItem } from 'root/apiTypes';
import type { HttpClientFactory } from './utils/types';
import { MenusClient } from './MenusClient';
import { SectionsClient } from './SectionsClient';
import { PopulatedItemsClient } from './ItemsClient';
import { populateMenu } from 'root/api/utils/populateMenu';
import { completeMissingEntities } from 'root/api/utils/completeMissingEntities';

export const PopulatedMenuClient: HttpClientFactory<PopulatedMenu> = (httpClient, withErrorHandler) => {
  const MenusApi = MenusClient(httpClient, withErrorHandler);
  const SectionsApi = SectionsClient(httpClient, withErrorHandler);
  const PopulatedItemsApi = PopulatedItemsClient(httpClient, withErrorHandler);

  return {
    getAll: async ({ paging, optimizedBulk = false, onlyVisible }) => {
      const [menus, sections, populatedItems] = await Promise.all([
        MenusApi.getAll({ paging, onlyVisible }),
        SectionsApi.getAll({ paging }),
        PopulatedItemsApi.getAll({ paging, optimizedBulk }),
      ]);

      await completeMissingEntities({
        menus: menus.data,
        sections,
        getSections: SectionsApi.getAll,
        populatedItems,
        getPopulatedItems: PopulatedItemsApi.getAll,
        maxNumberOfEntitiesToLoad: paging?.limit,
        optimizedBulk,
      });

      return {
        data: menus.data.map((menu) =>
          populateMenu(menu, sections.data, (populatedItems?.data ?? []) as ShowcasePopulatedItem[])
        ) as PopulatedMenu[],
      };
    },
  };
};
